.pdf-viewer-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;

    .controller-bar {
        display: flex;
        width: 100%;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.338);
        border-radius: 10px;
        align-items: center;
        padding: 0 1rem;
        gap: 1rem;
        box-sizing: border-box;

        .rpv-core__minimal-button {
            color: white;
        }

        .left-side {
            display: flex;
            width: 50%;
            align-items: center;

            .pdf-name-info {
                color: white;
            }
        }

        .right-side {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }


    }

    .pdf-viewer {
        position: relative;
        height: calc(100% - 40px);
        width: 100%;

        .rpv-core__inner-page {
            background-color: transparent !important;
        }

        .rpv-core__inner-pages {
            &::-webkit-scrollbar {
                width: 8px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-track {
                border-radius: 5px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #555;
            }
        }
    }
}