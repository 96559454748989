.query_input_container {
    position: relative;
    width: 90%;

    .microphone {
        font-size: var(--font-size-md);
        color: #1677ff;

        &.active {
            color: red;
        }
    }

    .search_options_container {
        position: absolute;
        left: 0;
        top: -10px;
        width: 100%;
        height: 200px;
        transform: translateY(-100%);
        z-index: 25;
        border-radius: 10px;
        padding: 10px;
        border: 1px solid gainsboro;
        background-color: white;

        ::-webkit-scrollbar {
            width: 10px;
        }

        ::-webkit-scrollbar-track {
            background: gainsboro;
        }

        ::-webkit-scrollbar-thumb {
            background: #eeeeee;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: white;
        }

        .search_options_content {
            position: relative;
            width: 100%;
            height: 100%;

            .questions_container {
                position: relative;
                width: 100%;
                height: 110px;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .question_item {
                    position: relative;
                    cursor: pointer;
                    margin-bottom: 2px;
                    width: 100%;
                    padding: 5px;
                    border-radius: 2px;

                    &:hover {
                        background-color: gainsboro;
                    }
                }
            }
        }
    }
}
