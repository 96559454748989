.query_container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #eeeeee;
    }

    &::-webkit-scrollbar-thumb {
        background: gainsboro;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: gray;
    }
}

.arrow_down_container {
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 24;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 4px gray inset;
    transition: all 0.2s ease-in-out;

    &.passive {
        opacity: 0;
        pointer-events: none;
    }

    &.active {
        opacity: 1;
    }

    &:hover {
        width: 45px;
        height: 45px;
        box-shadow: 0 0 5px gray inset;
    }
}
