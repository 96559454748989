.logs-page-container {
    .actions-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 3rem;
        gap: 0.5rem;

        .filter-dropdown {
            width: 200px;
        }
    }


}