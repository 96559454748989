.pdf-viewer-error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .error-message {
        background-color: #e53e3e;
        border-radius: 0.25rem;
        padding: 0.5rem;
        color: #fff;
    }
}