.quiz-chat-container {
    box-sizing: border-box;
    border-radius: 20px;
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .messages {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        row-gap: 1rem;

        .message {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 1rem;

            .point-container {
                display: flex;
                gap: 0.5rem;

                .isEvaluated {
                    font-weight: bold;
                    color: rgb(148, 0, 0);
                }

                .input {
                    width: 75px;
                }
            }
        }
    }

    .loading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem 0;
    }

    .modal-form {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        .modal-line {
            display: flex;
            flex-direction: column;
            row-gap: 0.2rem;

            span {
                color: rgb(62, 62, 62);
            }
        }
    }

    .rating-save-container {
        width: 100%;
        margin: 0.5rem 0;
        display: flex;
        justify-content: flex-end;

        .save-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.3rem;
            gap: 0.2rem;
            color: rgb(88, 88, 88);
            border-radius: 5px;
            background-color: white;

            &:disabled {
                background-color: rgb(201, 201, 201);
                cursor: no-drop;
            }
        }
    }
}