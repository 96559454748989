.message-container-wrapper {
  display: flex;
  margin-right: 1rem;

  .message-container {
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    padding-right: calc(0.8rem + 30px);
    background-color: rgb(73, 73, 73);
    border-radius: 10px;
    max-width: 75%;
    color: white;
    transition: 0.5s;
    opacity: 0.8;

    .page-numbers {
      display: flex;
      gap: 0.5rem;
      margin-top: 1rem;
      justify-content: flex-end;

      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        padding: 0 2px;
        cursor: pointer;
        border: 1px solid white;
        font-weight: bolder;
        transition: 1s;
      }

      .active-number {
        background-color: white;
        color: rgb(30 30 30) !important;
      }
    }

    .copy-button {
      visibility: hidden;
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.537);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.814);
      }
    }

    &:hover {
      .copy-button {
        visibility: visible;
      }
    }
  }

  .active-box {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: scale(1.03);
    opacity: 1;
  }
}

.reverse-box {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;

  .message-container {
    background-color: rgb(30 30 30);
  }
}
