.login-page {
    height: 100vh;

    .login-page-wrapper {
        display: flex;
        min-height: 100%;
        position: relative;

        .language-container {
            position: absolute;
            width: 100px;
            height: auto;
            right: 1rem;
            top: 1rem;
        }

        .image-container {
            background-color: #e1dfec;
            min-height: 100%;
            padding: 5%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;

            .group-image {
                width: 100%;
                display: block;
            }
        }

        .login-form-container {
            display: flex;
            justify-content: center;
            flex-grow: 2;
            background-color: white;
            min-height: 100%;
            padding: 5%;
            background: url(https://i.imgur.com/BKyjjFa.png) no-repeat center center fixed;
            background-size: cover;

            .login-form-wrapper {
                min-width: 60%;


                .logo-container {
                    .logo {
                        height: 60px;
                        margin-bottom: 30px;
                    }
                }

                .header {
                    font-size: 32px;
                    font-weight: 500;
                }

                .sub-header {
                    color: #9aa4ad;
                    margin-top: 5px;
                    margin-bottom: 40px;
                }

                .input-container {
                    color: #3f3f45;
                    margin: 20px 0px;

                    .label {
                        display: flex;
                        width: 100%;
                        justify-content: flex-start;
                        align-items: center;
                        margin-right: 7px;
                        margin-bottom: 10px;

                        .label-icon {
                            width: 20px;
                            margin-right: 10px;
                            display: block;
                        }
                    }

                    .input {
                        display: block;
                        width: calc(100% - 20px);
                        font-size: 15px;
                        padding: 10px;
                        border: 1px solid #ced4da;
                        border-radius: 5px;
                        margin-top: 5px;
                        outline: 0px !important;
                    }
                }

                .options-container {
                    display: flex;
                    justify-content: space-between;
                }

                .checkbox {
                    width: 15px;
                    height: 15px;
                    margin: 0px;
                    display: block;
                    border: 1px solid #ced4da;
                }

                .checkbox-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    label {
                        display: block;
                        padding: 0px 5px;
                        color: #9aa4ad;
                    }
                }

                .forgot-password-link {
                    color: #e7483b;
                    text-decoration: none;
                }

                .login-button {
                    margin-top: 30px;
                    display: block;
                    width: 100%;
                    padding: 10px;
                    border-radius: 20px;
                    font-weight: bold;
                    color: white;
                    background-color: #2e1f7a;
                    border: 0px;
                    outline: 0px;
                    cursor: pointer;

                    &:active {
                        background-color: #4520ff;
                    }

                    &:disabled {
                        background-color: #9b98a8;
                    }
                }

                .other-login-container {
                    display: flex;
                    margin: 1rem;
                    gap: 0.5rem;
                }
            }
        }
    }


    @media only screen and (max-width: 800px) {
        .login-page-wrapper .image-container {
            display: none;
        }

        .login-form-container {
            justify-content: center;
        }
    }
}