.verification-container {
    height: calc(100% - 40px);

    .verification-bar {
        display: flex;
        height: 40px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: rgb(218, 86, 86);
        color: white;
        align-items: center;
        padding: 0 1rem;
        justify-content: space-between;
        margin: -1rem;
        margin-bottom: 1rem;

        .verification-info {
            display: flex;
            align-items: center;

            gap: 0.5rem;
        }

        .resend-button {
            background-color: transparent;
            border: 1px solid white;
            border-radius: 0.5rem;
            padding: 0 0.5rem;
            color: white;
            cursor: pointer;

            &:hover {
                color: black;
                background-color: white;
            }
        }
    }
}