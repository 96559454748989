.question-boxes {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .question-box-container {
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: 0.2s;
        color: black;
        max-height: 400px;
        background-color: white;

        .box-header {
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: large;
            margin-bottom: 0.5rem;
        }

        .box-content {
            display: flex;
            flex-direction: column;
            padding: 0.2rem;
            height: 100%;
            overflow: auto;
            gap: 1rem;

        }

    }
}