.context-page-container {
    display: flex;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    justify-content: space-between;

    .left-side {
        width: 45%;
    }

    .right-side {
        width: 50%;
        height: 100%;
    }
}

@media screen and (max-width: 768px) {
    .context-page-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        flex-wrap: wrap;

        .left-side {
            width: 100%;
            height: 100%;

            .chat-container {
                justify-content: space-between;
                height: 100%;
            }
        }

        .right-side {
            width: 100%;
            height: 100%;
        }
    }
}