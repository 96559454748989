.navbar-container {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #001529db;
    padding: 0 2 0 0rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .navbar-left-side {
        display: flex;
        align-items: center;

        .navbar-logo-wrapper {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            
            .navbar-logo {
                cursor: pointer;
                font-size: xx-large;
                font-weight: bolder;
                color: white;
                margin-top: 15px;
                // margin-right: 1rem;
                img {
                    width: 80px;
                    height: auto;
                }
            }
        }
        

        .navbar-pages {
            display: flex;
            transition: 1s;

            a {
                padding: 0.2rem 0.5rem;
                color: white;
                border-bottom: 2px solid transparent;
                transition: 1s;


            }

            .active {
                border-bottom: 2px solid white;

            }
        }
    }


    .navbar-right-side {
        display: flex;
        align-items: center;
        gap: 1rem;

        .user-img {
            border: 1px solid white;
            border-radius: 50%;
            padding: 2px;
            display: flex;
            max-height: 50px;

            img {
                width: 40px;
                border-radius: 50%;
                height: auto;
                max-height: 50px;
            }
        }

        .user-name {
            line-height: normal;
            color: white;
        }

        .logout-button {
            display: flex;
            align-items: center;
            cursor: pointer;

            .icon {
                color: white;
            }
        }

        .settings-button {
            display: flex;
            align-items: center;
            cursor: pointer;

            .icon {
                color: white;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .navbar-container {
        font-size: x-small;
        padding: 0 1rem;
        gap: 0.5rem;

        .navbar-right-side{
            gap: 0.5rem;
        }

        .navbar-logo-wrapper {
            display: none;
        }

        .navbar-logo {
            cursor: pointer;
            font-size: 10px;
            font-weight: bolder;
            color: white;
            // margin-right: 5rem;
            img {
                width: 40px !important;
                height: auto;
            }
        }

        .user-img {
            width: 1rem;
            height: 1rem;

            img {
                width: 1rem !important;
                height: 1rem !important;
            }
        }
    }

}