.pdf-package-container {
    width: 100%;
    .add-package-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;

        button {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            background-color: rgb(255, 255, 255);
            border: 1px solid #ced4da;

            &:hover {
                background-color: rgb(205, 205, 205);
            }
        }
    }
}