.avatar_container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .ant-avatar {
        background: #373737;

        &-string {
            font-weight: bold;
        }
    }

    &__display_name {
        margin-left: 5px;
        font-weight: bold;
        font-size: var(--font-size-sm);
    }
}
