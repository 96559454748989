$loading-border-color: white;

.loading-wrapper {
    background-color: rgba(0, 0, 0, 0.733);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;


}

.loading-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 90px;
    height: 90px;
    margin: -85px 0 0 -85px;
    border: 3px solid transparent;
    border-top-color: $loading-border-color ;
    border-bottom-color: $loading-border-color ;
    border-radius: 50%;
    animation: loader 2s linear infinite;

    &::after {
        content: "";
        position: absolute;
        border: 3px solid transparent;
        left: 20px;
        right: 20px;
        top: 20px;
        bottom: 20px;
        border-top-color: $loading-border-color ;
        border-bottom-color: $loading-border-color ;
        border-radius: 50%;
        animation: loader 1.5s linear infinite;
    }

    &::before {
        content: "";
        position: absolute;
        border: 3px solid transparent;
        left: 10px;
        right: 10px;
        top: 10px;
        bottom: 10px;
        border-top-color: $loading-border-color ;
        border-bottom-color: $loading-border-color ;
        border-radius: 50%;
        animation: loader 3s linear infinite;
    }
}


@keyframes three-sticks-loader {
    0% {
        bottom: 0px;
        height: 38px;
    }

    50%,
    100% {
        bottom: 12px;
        height: 16px;
    }
}

.three-sticks-loader {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 50px;

    div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 8px;
        background: rgba(0, 0, 0, 0.663);
        animation: three-sticks-loader 0.45s cubic-bezier(0, 0.5, 0.5, 1) infinite;

        &:nth-child(1) {
            left: 0px;
            animation-delay: -0.30s;
        }

        &:nth-child(2) {
            left: 16px;
            animation-delay: -0.15s;
        }

        &:nth-child(3) {
            left: 32px;
            animation-delay: 0;
        }
    }
}


@keyframes loader {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}