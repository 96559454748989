.dropdown-container {
  position: relative;
  width: 100%;
  display: inline-table;
  cursor: pointer;


  .dropdown-toggle {
    width: 100%;
    padding: 0.5rem 0.75rem;
    padding-right: 1.625rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out;
    background-color: white;
    outline: none;
    cursor: pointer;
    text-align: start;
    height: 40px;

    &:focus {
      border-color: #ced4da;
    }

    svg {
      position: absolute;
      right: 0.313rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1rem;
    }
  }

  .open {
    svg {
      transform: rotate(180deg) translateY(50%);
    }
  }

  .dropdown-menu {
    position: absolute;
    left: 0;
    top: 95%;
    transform: translateY(5%);
    width: 100%;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 100;
    padding: 0.5rem 0;
    box-sizing: border-box;

    li {
      box-sizing: border-box;
      list-style: none;
      width: 100%;
      padding: 0.5rem;
      cursor: pointer;
      font-size: 0.875rem;
      transition: background-color 0.15s ease-in-out;
      white-space: pre-wrap;

      &:hover {
        background-color: rgba(128, 128, 128, 0.422);
        color: white;
      }
    }
  }


  .legend {
    border-radius: 10px;
    position: absolute;
    top: -0.5rem;
    left: 0.75rem;
    padding: 0 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1;
    color: #495057;
    background-color: white;
  }

  .error-message {
    position: absolute;
    bottom: 0;
    left: 0.75rem;
    transform: translateY(calc(50%));
    padding: 0 0.25rem;
    font-weight: 500;
    line-height: 1;
    background-color: white;
    font-size: 12px;
  }
}



.dropdown-container-error {
  .dropdown-toggle {
    border-color: red;
  }

  label {
    color: red;
  }
}