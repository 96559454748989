.comment_container {
    margin-block: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .modal_fields {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-block: 10px;
    }

    .microphone_container {
        justify-content: flex-end;
    }
}