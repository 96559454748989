.add-document-package-modal {
    width: 50%;

    .modal-line {
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;
        justify-content: center;
        margin-bottom: 1rem;

        .dropzone {
            height: 0;
        }
    }

    .pdfs {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1rem;
        display: flex;
        overflow: auto;
        max-height: 300px;
        padding-right: 1rem;

        .pdf {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;



        }
    }

    .footer {
        width: 100%;
        margin: 0.5rem 0;
        display: flex;
        justify-content: flex-end;

        .save-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.3rem;
            gap: 0.2rem;
            border-radius: 5px;
            background-color: white !important;
        }
    }
}