.faThumbTack {
    cursor: pointer;
    margin-inline: 2px;
}

.th_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    align-items: center;
}

.ant-table-sticky-scroll {
    display: none !important;
}