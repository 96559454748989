.field-set {
  position: relative;
  border: none;
  outline: none;

  .legend {
    border-radius: 10px;
    position: absolute;
    top: -0.5rem;
    left: 0.75rem;
    padding: 0 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1;
    color: #495057;
    background-color: white;
  }

  .input {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out;
    background-color: white;
    outline: none;
    box-sizing: border-box;
    height: 40px;

    &::placeholder {
      color: var("sub");
      opacity: 1;
    }
  }

  .error-message {
    position: absolute;
    bottom: 0;
    left: 0.75rem;
    transform: translateY(calc(50%));
    padding: 0 0.25rem;
    font-weight: 500;
    line-height: 1;
    background-color: white;
    font-size: 12px;
  }

}

.field-set-error {
  .input {
    border-color: red;
  }

  label {
    color: red;
  }
}