.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #00000038;
  align-items: center;
  justify-content: center;
}

.modal-container {
  background-color: white;
  border-radius: 4px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: min-content;
  max-height: 85%;
  min-width: fit-content;
  margin: 1rem;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: fit-content;
  padding: 16px 24px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  border-bottom: 1px solid #00000038;
  height: 10%;
  box-sizing: border-box;

  span {
    cursor: pointer;
  }
}

.modal-body {
  box-sizing: border-box;
  padding: 16px 24px;
  height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
}

.open {
  display: flex;
}

