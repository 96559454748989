.quizzes-container {
    .actions-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
        gap: 0.5rem;

        .filter-dropdown {
            width: 200px;
        }
    }

    .quizzes-content {
        display: flex;
        gap: 1rem;
        row-gap: 2.5rem;
        flex-wrap: wrap;

        .quiz-box-container {
            width: calc(50% - 0.5rem);
        }
    }
}

@media (max-width: 768px) {
    .quizzes-container .quizzes-content .quiz-box-container {
        width: 100%;
    }
}