.form-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 80%;
    justify-content: space-between;

    .microphone {
        cursor: pointer;
    }

    .prompt-container {
        cursor: pointer;
        margin-bottom: 1rem;
        height: fit-content;
        overflow-y: auto;
        overflow-x: hidden;

        ul {
            list-style-type: none; 
            display: flex;
            flex-direction: column;
            gap: 0.75rem; 
            margin-left: 1.5rem;
            padding-left: 0; 
        }
        
        ul li {
            font-size: 1rem;
            margin-left: 0.5rem; 
            padding: 0.5rem 0.75rem; 
            position: relative; 
            cursor: pointer; 
            transition: all 0.3s ease-in-out; 
        }
        
        ul li::before {
            content: '•'; 
            color: #3498db; 
            position: absolute;
            left: -1.5rem;
            font-size: 1.25rem; 
            transition: color 0.3s ease-in-out;
        }
        
        ul li:hover {
            color: #3498db; 
            transform: translateX(10px); 
        }
        
        ul li:hover::before {
            color: #e74c3c; 
        }
        
    }

    .form-line {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90%;

        .label-container {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            span {
                color: rgb(62, 62, 62);
            }

            svg {
                color: rgba(125, 125, 125, 0.415);
            }
        }

        .text-input {
            max-height: 250px;

        }
    }

    .send-button {
        display: flex;
        flex-direction: row-reverse;
        height: 8%;

        &:disabled {
            svg {
                color: black;
            }
        }
    }

    .response-container {
        line-height: 25px;
    }
}