.create-question-package-modal {
    width: 50%;

    .modal-line {
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;
        justify-content: center;
        margin-bottom: 1rem;

        span {
            color: rgb(62, 62, 62);
        }

        textarea {
            width: 92%;
        }

        .add-container {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            button {
                background-color: transparent;

            }
        }
    }

    .questions {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1rem;
        display: flex;
        overflow: auto;
        max-height: 300px;
        padding-right: 1rem;

        .question {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;


            button {
                background-color: transparent;

            }
        }
    }

    .footer {
        width: 100%;
        margin: 0.5rem 0;
        display: flex;
        justify-content: flex-end;

        .save-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.3rem;
            gap: 0.2rem;
            color: rgb(88, 88, 88);
            border-radius: 5px;
            background-color: rgb(255, 255, 255);

            &:disabled {
                background-color: rgb(201, 201, 201);
                cursor: no-drop;
            }
        }
    }
}