.button {
  min-width: fit-content;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #ced4da;
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: rgb(205, 205, 205);
  }

  &:disabled {
    background-color: rgba(236, 233, 233, 0.686);
  }
}

.done-button {
  border: 1px solid green;

}

.error-button {
  border: 1px solid rgb(153, 10, 10);
}

.inprogress-button {
  border: 1px solid rgb(43, 19, 158);

  svg {
    animation: rotation 2s infinite linear;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}