.edit-context-modal {
    .footer {
        width: 100%;
        margin: 0.5rem 0;
        display: flex;
        justify-content: flex-end;

        .save-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.3rem;
            gap: 0.2rem;
            border-radius: 5px;
            background-color: white !important;
        }
    }
}