.analysis-table-container {
    height: 100%;
    overflow: auto;

    .expand-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .log-info {
            display: flex;
            width: 100%;

            .text {
                width: 20%;
            }

            .num {
                width: 15%;
            }
        }
    }
}