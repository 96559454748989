.chat-container {
    box-sizing: border-box;
    border-radius: 20px;
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .collection-info {
        display: flex;
        margin-top: -2rem;
        justify-content: center;
        padding: 1rem;
        border-radius: 0 1rem 1rem 0;
        font-size: large;
        font-weight: 500;
    }

    .messages {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        row-gap: 1rem;

        .message {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 1rem;

            .point-container {
                display: flex;
                gap: 0.5rem;

                .isEvaluated {
                    font-weight: bold;
                    color: rgb(148, 0, 0);
                }

                .input {
                    width: 75px;
                }
            }
        }
    }

    .loading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem 0;
    }

    .send-message-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .send-message {
            display: flex;
            align-items: center;
            width: 70%;
            border: 1px solid #ced4da;
            border-radius: 4px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


            .text-input {
                max-height: 250px;
                border: none;
                box-shadow: none;
            }

            .send-button {
                margin: 0 0.5rem;
                color: gray;
                background-color: transparent;
                border: none;

                &:hover {
                    color: rgb(86, 86, 86);
                }
            }
        }
    }

    .modal-form {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        .modal-line {
            display: flex;
            flex-direction: column;
            row-gap: 0.2rem;

            span {
                color: rgb(62, 62, 62);
            }
        }
    }

    .rating-save-container {
        width: 100%;
        margin: 0.5rem 0;
        display: flex;
        justify-content: flex-end;

        .save-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.3rem;
            gap: 0.2rem;
            color: rgb(88, 88, 88);
            border-radius: 5px;
            background-color: rgb(255, 255, 255);

            &:disabled {
                background-color: rgb(201, 201, 201);
                cursor: no-drop;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .chat-container {
        padding: 0;

        .collection-info {
            padding: 0;
            margin: 0;
        }

        .send-message-wrapper {
            .send-message {
                width: 100%;

                .button {
                    width: 1rem;
                    height: 1rem;
                    padding: 0;

                    svg {
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .message {
        .message-container-wrapper{
            .message-container{
                font-size: small;
                padding: 0.5rem;
                max-width: 80%;
                .page-numbers{
                    flex-wrap: wrap;
                }
            }
        }
    }
}