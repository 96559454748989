.quiz-box-container {
    position: relative;
    transition: 0.2s;

    &:hover {
        transform: scale(1.005);
    }

    .quiz-box {
        width: 100%;
        border: 1px solid #ced4da;
        box-sizing: border-box;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;

        color: black;
        cursor: pointer;


        .box-header {
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: large;
            margin-bottom: 0.5rem;
        }

        .box-content {
            display: flex;
            justify-content: space-between;
            column-gap: 10%;

            .line {
                display: flex;
                gap: 0.5rem;

                .label {
                    color: rgb(85, 85, 85);
                }

                .value {
                    font-weight: 500;
                    color: black;
                }
            }

            .config-info {
                width: 45%;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;


            }

            .right-side {
                width: 45%;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                overflow: hidden;
            }

            .questions-info {
                list-style-type: disc;
                display: flex;
                width: 70%;
                flex-direction: column;
                gap: 0.5rem;
                box-sizing: border-box;
                padding-right: 2rem;


                .question-line {
                    display: flex;
                    justify-content: space-between;

                    .question {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &::before {
                            content: "\2022";
                        }
                    }

                    .point {
                        display: flex;
                        align-items: center;
                        margin-left: 1rem;
                    }


                }
            }
        }

        .box-footer {
            display: flex;
            width: 100%;
            margin-top: 1rem;
            justify-content: flex-end;
            gap: 0.5rem;

            .label {
                color: rgb(85, 85, 85);
            }

            .value {
                font-weight: bold;
            }
        }



    }

    .checkbox-container {
        position: absolute;
        top: 0;
        transform: translateY(calc(-100% + 2px));
        left: 0;
        background-color: white;
        padding: 8px;
        border: 1px solid #ced4da;
        border-bottom: none;
        box-sizing: border-box;

        .checkbox {
            width: 1.1rem;
            height: 1.1rem;
        }
    }
}