.chat-content {
    position: relative;
    width: 100%;
    height: 44vh;
    overflow-y: auto;
    
    &__content {
        position: relative;
        width: 100%;
        height: 86%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    &__bottom {
        position: relative;
        width: 100%;
        height: 14%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .placeholder-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}