.patient-detail-container {
    padding: 24;
    background: #f5f5f5;
    min-height: 100vh;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .ant-card-head {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;
    }

    .patient-side-bar {
        position: sticky;
        width: 750px;
        height: 85vh;
        top: 0;
        margin-left: 10px;
        overflow-y: auto;
    }
}

@media (max-width: 768px) {
    .patient-detail-container .patient-side-bar {
        position: fixed;
        width: 100%;
        left: -10px;
        top: 20vh;
        height: 70vh;
    }
}