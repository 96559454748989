.ant-input {
    margin-right: 10px;
}

.ant-picker {
    margin-right: 10px;
}

.ant-form-item {
    margin-right: 10px !important;
}