.layout-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;

    .navbar-container {
        height: 80px;
    }

    .page-container {
        height: 100%;
    }

    .ant-layout-sider-zero-width-trigger {
        top: 3px;
    }
}