.yevmiye-analysis-page-container {
    display: flex;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden !important;

    .left-side {
        position: sticky;
        top: 0;
        width: 28%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .tab-container {
            height: 10%;
        }

        .loading-container {
            display: flex;
            justify-content: center;
            height: 10%;
        }
    }

    .right-side {
        width: 70%;
        height: 100%;
        padding: 10px;

        .response_tab_container {
            overflow-x: auto;
            white-space: nowrap;

            .tabs {
                margin-bottom: 0;
            }
        }

        .question-container {
            margin-bottom: 10px;
            white-space: pre-wrap;
        }

        .action-container {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 1rem;

            .download-data {
                min-width: fit-content;
                width: 40px;
                height: 40px;
                border-radius: 5px;
                background-color: rgb(255, 255, 255);
                border: 1px solid #ced4da;
                padding: 0.5rem;
                color: black;
                cursor: pointer;
                box-sizing: border-box;
            }
        }

        .answer-container {
            width: 100%;

            .answer-text {
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .yevmiye-analysis-page-container {
        flex-direction: column;

        .left-side {
            width: fit-content;
            position: relative;
        }

        .right-side {
            width: 100%;
        }
    }
}