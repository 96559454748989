.ask-modal-container {
  // min-width: 30%;
  width: 40%;
  height: 95%;

  .config-modal-form {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .types-container {
      display: flex;
      margin-bottom: 1rem;
    }

    .modal-line {
      display: flex;
      flex-direction: column;
      row-gap: 0.2rem;
      margin-bottom: 1rem;

      .label-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        span {
          color: rgb(62, 62, 62);
        }

        svg {
          color: rgba(125, 125, 125, 0.415);
        }
      }

      .reduction-dropdown-select {
        display: flex;
        column-gap: 0.5rem;

        svg {
          color: rgba(125, 125, 125, 0.415);
        }

        .tooltip-container {
          width: 20px;
        }
      }
    }
  }
}