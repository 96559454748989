$md-radio-checked-color: rgb(40, 40, 40);
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 15px;
$md-radio-checked-size: 8px;
$md-radio-ripple-size: 0px;

@keyframes ripple {

    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
    }

    50% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
    }

    100% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
    }
}

.radio-button {
    margin-bottom: 1rem;

    input[type="radio"] {
        display: none;

        &:checked+label:before {
            border-color: $md-radio-checked-color;
            animation: ripple 0.2s linear forwards;
        }

        &:checked+label:after {
            transform: scale(1);
        }
    }

    label {
        font-size: medium;
        display: flex;
        align-items: center;
        justify-content: center;
        height: $md-radio-size;
        position: relative;
        padding: 0 ($md-radio-size + 10px);
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;

        &:before,
        &:after {
            position: absolute;
            content: '';
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }

        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 2px solid $md-radio-border-color;
        }

        &:after {
            top: $md-radio-size / 2 - $md-radio-checked-size / 3;
            left: $md-radio-size / 2 - $md-radio-checked-size / 3;
            width: $md-radio-checked-size;
            height: $md-radio-checked-size;
            transform: scale(0);
            background: $md-radio-checked-color;
        }
    }
}