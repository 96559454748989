.table-container {
  display: flex;
  flex-direction: column;

  .table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    color: #333;

    th,
    td {
      padding: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }

    th {
      background-color: #f2f2f2;
      font-weight: bold;

      .sort-icon {
        margin-left: 0.5rem;
      }
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tr:hover {
      background-color: #eaeaea;
    }
  }

  .download-button-container {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}