.dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    box-sizing: border-box;
    border: 3px dashed rgb(117, 112, 112);
    border-radius: 20px;
    padding: 2rem;
    background-color: rgba(211, 211, 211, 0.37);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    &:hover {
        background-color: rgba(211, 211, 211, 0.616);
    }
}