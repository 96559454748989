.github-container {
    display: flex;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    color: white;
    background-color: #2e1f7a;
    align-items: center;
    justify-content: center;
    line-height: 1;
    gap: 0.5rem;
    cursor: pointer;
}