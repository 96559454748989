.query_items {
    position: relative;
    width: 90%;
    margin-left: 5%;

    .ant-list-item {
        .skeletonContainer {
            margin: 0;
            width: 100%;
        }

        .ant-list-item-meta-title {
            font-size: var(--font-size-md) !important;
        }

        .item-footer {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            visibility: hidden;
            transition: visibility 0.3s ease-in-out;

            svg {
                margin-inline: 3px;
                cursor: pointer;
            }
        }

        &:hover .item-footer {
            visibility: visible;
        }
    }
}
