.auto-size-textarea {
  position: relative;
  border: none;
  outline: none;
  width: 100%;

  .legend {
    border-radius: 10px;
    position: absolute;
    top: -0.5rem;
    left: 0.75rem;
    padding: 0 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1;
    color: #495057;
    background-color: white;
    z-index: 2;
  }

  textarea {
    resize: none;
    overflow-y: hidden;
    width: calc(100% - 2rem);
    padding: 1rem;
    font-size: 14px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    overflow: auto;
    position: relative;


    &:focus {
      outline: none;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

  .error-message {
    position: absolute;
    bottom: 0;
    left: 0.75rem;
    transform: translateY(calc(50%));
    padding: 0 0.25rem;
    font-weight: 500;
    line-height: 1;
    background-color: white;
    font-size: 12px;
  }

}

.auto-size-textarea-error {
  textarea {
    border-color: red;
  }

  label {
    color: red;
  }
}