.indicator-layout-container {
  display: flex;
  height: 100vh;

  &.preview-mode {
    .flexlayout__tabset_tabbar_outer {
      display: none !important;
    }
    .flexlayout__splitter {
      display: none !important;
    }
    .flexlayout__tab {
      padding-inline: 10px;
    }
    .flexlayout__tabset_content {
      background-color: white;
    }
  }

  .unmounted-indicators {
    width: 200px;
    padding: 10px;
    border-right: 1px solid #ccc;
    overflow-y: auto;
    background-color: #f9f9f9;
    position: relative;

    li {
      margin-bottom: 10px;
    }
  }

  .layout-side {
    position: relative;
    flex: 1;
  }
}
