.pdf-packages-boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 1rem;

    .package-box-container {
        display: flex;
        flex-direction: column;
        width: calc(50% - 0.5rem);
        border: 1px solid #ced4da;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 0.5rem;
        color: black;
        max-height: 400px;
        background-color: white;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: 0.2s;
        margin-bottom: 1rem;

        .box-header {
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: large;
            margin-bottom: 0.5rem;
        }

        .box-content {
            display: flex;
            flex-direction: column;
            padding: 0.2rem;
            height: 100%;
            overflow: auto;
            gap: 1rem;

            .context-info {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                margin-bottom: 1rem;

                .label {
                    font-weight: bold;

                    .edit-context-button {
                        background-color: transparent;
                        border: none;

                        svg {
                            color: rgb(80, 80, 80);
                        }

                        &:hover {
                            svg {
                                color: black;
                            }
                        }
                    }
                }
            }

            .document-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 0.5rem;
                margin-bottom: 1rem;

                .name {
                    display: flex;
                    column-gap: 0.5rem;
                    width: 70%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .spinner {
                    svg {
                        animation: rotation 2s infinite linear;
                    }
                }

                .error-document {
                    svg {
                        color: red;
                    }
                }

                .actions {
                    display: flex;
                    gap: 0.5rem;

                    .action-button {
                        width: 2rem;
                        height: 2rem;
                        padding: 0;
                    }
                }
            }

        }

        .box-footer {
            display: flex;
            justify-content: flex-end;

            .actions {
                display: flex;
                gap: 0.5rem;
            }

            .juBESy {
                input {
                    width: 0;
                }
            }
        }

    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}


@media screen and (max-width: 768px) {
    .package-box-container {
        width: 100% !important;
    }
}