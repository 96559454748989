.hospital-logs-page-container {
    display: flex;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    position: relative;
    overflow: scroll;

    .left-side {
        position: sticky;
        top: 0;
        width: 28%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .user_dropdown {
            width: 205px;
            height: 40px;
        }

        .inputContainer {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
        }

        .prompt-container {
            cursor: pointer;
            margin-bottom: 1rem;
            height: fit-content;
            overflow: auto;
    
            ul {
                list-style-type: circle;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                margin-left: 1.5rem;
    
                li {
                    font-size: medium;
                    margin-left: 0.1rem;
                    padding: 3px;
                    border-radius: 2px;
                }
            }
        }
    }

    .right-side {
        width: 70%;
        height: 100%;

        .response_tab_container {
            overflow-x: auto;
            white-space: nowrap;

            .tabs {
                margin-bottom: 0;
            }
        }

        .answer-container {
            width: 100%;
            overflow: auto;

            .answer-text {
                font-weight: bold;
            }
        }

        .fieldValue {
            margin-left: 30px;
            margin-block: 5px;
        }

        .fieldLabel {
            font-weight: bold;

            &:last-child {
                margin-bottom: 5px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .hospital-logs-page-container {
        flex-direction: column;

        .left-side {
            width: fit-content;
            position: relative;
        }

        .right-side {
            width: 100%;
        }
    }
}