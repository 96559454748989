.tabs {
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 20px;
    height: 40px;

    .tab {
        height: 30px;
        cursor: pointer;
        font-size: 0.889rem;
        display: flex;
        align-items: center;
        line-height: normal;
        color: rgb(73, 73, 73);
        padding: 0 0.444rem;
        flex-direction: row-reverse;

        &.active {
            color: rgb(30, 30, 30);
            border-bottom: 2px solid rgb(30, 30, 30);
            font-weight: bold;

        }

        &.disabled {
            color: rgb(140, 138, 138);
            cursor: not-allowed;
        }

        &-icon {
            display: flex;
            align-items: center;
            padding-right: 0.444rem;
        }
    }
}