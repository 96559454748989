.quiz-modal-container {
  min-width: 40%;

  .create-quiz-modal {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .modal-line {
      display: flex;
      flex-direction: column;
      row-gap: 0.2rem;
      margin-bottom: 1rem;

      .label-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        span {
          color: rgb(62, 62, 62);
        }

        svg {
          color: rgba(125, 125, 125, 0.415);
        }
      }

      .reduction-dropdown-select {
        display: flex;
        column-gap: 0.5rem;

        svg {
          color: rgba(125, 125, 125, 0.415);
        }

        .tooltip-container {
          width: 20px;
        }
      }
    }

    .footer {
      width: 100%;
      margin: 0.5rem 0;
      display: flex;
      justify-content: flex-end;

      .save-button {
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }
  }
}